import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import config from '../config.js';

import './Payment.css';

export default function Payment() {
   const stripe = useStripe();
   const elements = useElements();
   
   const [ errorMessage, setErrorMessage ] = useState(null);
   
   const handleSubmit = async (event) => {
      event.preventDefault();
   
      if (!stripe || !elements) {
         return;
      }
   
      const { error } = await stripe.confirmPayment({
         elements,
         confirmParams: { return_url: config.stripe.returnUrl }
      });
   
      if (error) {
         // This point will only be reached if there is an immediate error when
         // confirming the payment. Show error to your customer (for example, payment
         // details incomplete)
         setErrorMessage(error.message);
      }
      else {
         // Your customer will be redirected to your `return_url`. For some payment
         // methods like iDEAL, your customer will be redirected to an intermediate
         // site first to authorize the payment, then redirected to the `return_url`.
      }
   };

   return (
      <form className='Payment' onSubmit={handleSubmit}>
         <PaymentElement />
         <button disabled={!stripe}>Плати</button>
         {errorMessage
            ? <div>{errorMessage}</div>
            : null
         }
      </form>
   );
};