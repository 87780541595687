const config = {
   production: {
      stripe: {
         publicKey: 'pk_live_51MqWL7GHklqWEdZop4rSctVRaZGHVcBS6KJ30k2vn23ZFeoVrAhUfmsk3Nhp6WbxY7AKhh5l8BXw9dHKbChZueUG00KFc4HXLu',
         returnUrl: 'https://priem.cc'
      }
   },
   development: {
      stripe: {
         publicKey: 'pk_test_51MqWL7GHklqWEdZohQ4ErKBV7gaKT9y5bhRzCVcEY7WmF4CEycdyK1tbFcA0b5juEiWwm8mNtwE3Y9dOQ81T6Ars00DZfRpZMp',
         returnUrl: 'http://localhost:3000'
      }
   }
};
const environment = config[process.env.NODE_ENV] ? process.env.NODE_ENV : 'development';
console.log('environment:', environment);

export default config[environment];