import React, { Component } from 'react';

import './Chances.css';

export default class Chances extends Component {
   render() {
      const items = this.props.chances.map((item, index) => {
         const advanced = item.competition.every(child => child.weight >= 0);
         return (
            <details key={this.props.timestamp + index}>
               <summary>
                  <strong><em style={{ transform: `scaleX(${item.chance})`, transformOrigin: 'center left' }}></em>{parseInt(item.chance * 100)}%</strong>
                  <b>{item.vacancies}</b>
                  <i>{item.score} ({item.bonus})</i>
                  <span>{item.name} - {item.list}</span>
               </summary>
               <small>
                  <strong>Име</strong>
                  <b>Номер</b>
                  <i>Точки</i>
                  {advanced && <i>Тежест</i>}
                  {advanced && <span>Сигурен прием</span>}
               </small>
               <ol>
                  {item.competition.map((child, index) => (
                     <li className={child.certain && 'certain'} key={this.props.timestamp + index}>
                        <p>
                           <strong>{child.name}</strong>
                           <b>{child.id}</b>
                           <i>{child.score} ({child.bonus})</i>
                           {advanced && <i>{parseInt(child.weight * 100)}%</i>}
                           {advanced && <span>{child.certain || '-'}</span>}
                        </p>
                     </li>
                  ))}
               </ol>
            </details>
         );
      });

      return (
         items.length ?
            <section className='Chances'>
               <small>
                  <strong>Шанс</strong>
                  <b>Места</b>
                  <i>Точки</i>
                  <span>Детска градина</span>
               </small>
               {items}
            </section>
         : null
      );
   }
}