import React, { Component } from 'react';

import './Select.css';

export default class Select extends Component {
   constructor(props) {
      super(props);

      this.onSelect = this.onSelect.bind(this);
   }

   onSelect(event) {
      const { options } = this.props;
      event.target.blur();

      const selected = options.find(option => String(option.id) === event.target.value);
      this.props.onSelect(selected);
   }

   render() {
      let { options, selected } = this.props;
      selected = selected ? selected.id : "";
      options = options.map((option, index) => (
         <option key={index} value={option.id}>{option.name || option.id}</option>
      ));

      return (
         <div className="Select">
            <span><b>^</b></span>
            <select defaultValue={selected} onChange={this.onSelect}>
               <option value="" disabled>{this.props.placeholder}</option>
               {options}
            </select>
         </div>
      );
   }
}