import React, { Component } from 'react';

import './Description.css';

export default class Description extends Component {
   render() {
      return (
         <section className="Description">
            <article>
               <h3>История</h3>
               <p>Калкулаторът за изчисляване на шансове за прием в детски градини в София е инструмент написан с идеята да помогне на моето дете през далечната вече 2020 да се класира за място в детска градина.</p>
               <p>Подобни калкулатори имаше и тогава, има и сега, но нито един от тях не прави достатъчно дълбок анализ на конкурентите и тяхната тежест като такива (повече за това по-надолу). Ето защо седнах и написах собствен калкулатор на шансове за прием и благодарение на него се класирахме от първия път.</p>
               <p>От тогава го поддържам и актуализирам всяка година, за да може да продължи да помага и на други деца да увеличат своите шансове за прием, като подредят по най-оптималния начин своите желания.</p>
               <h3>Описание</h3>
               <p>Калкулаторът изчислява шансовете за прием в детска градина на базата на предварителна успешна регистрация в системата на детските градини на София (<b>kg.sofia.bg</b>):</p>
               <ul>
                  <li>Въвежда се наборът на детето, неговото валидно ID, издадено по време на регистрацията в системата и се натиска бутонът <b>Изчисли</b>.</li>
                  <li>Kалкулаторът изтегля от системата всички данни за съответния набор - детски градини, критерии, по които детето кандидатства, и списъци с кандидатстващи деца.</li>
                  <li>За всяка детска градина и критерий се прави отделно изчисление на шансовете за прием.</li>
                  <li>Изчислените шансове за всяка детска градина и критерий се показват в табличен вид, в началото на всеки ред от таблицата и са в проценти.</li>
               </ul>
               <p><b>Важно:</b> Калкулаторът кешира изтеглените от системата данни за всеки набор. За това е необходимо да се изчакат 5-10 минути, след всяка промяна на желанията в системата, за да бъдат правилно изчислени новите шансове за прием.</p>
               <h3>Дълбок анализ</h3>
               <p>За да се определи точно шансът на детето, в повечето случаи се налага дълбок анализ на шансовете за прием и на останалите преки конкуренти за всяка градина и критерий:</p>
               <ul>
                  <li>Резултатите от дълбокия анализ се виждат след избор на някой от редовете в таблицата с изчислените шансове и представляват списък с информация за всички преки конкуренти:
                     <ul>
                        <li><b>Име</b> - начални букви на трите имена</li>
                        <li><b>Номер</b> - валиден номер в системата</li>
                        <li><b>Точки</b> - общо точки (включен бонус за първите 3 желания)</li>
                     </ul>
                  </li>
                  <li>При по-сложните случаи на дълбок анализ се показва и допълнителна информация за конкурентите:
                     <ul>
                        <li><b>Tежест</b> - шанс конкурентът да не бъде приет на нито едно по-предно желание и да се бори за прием в тази градина</li>
                        <li><b>Сигурен прием</b> - къде на по-предно желание конкурентът ще бъде приет със 100% сигурност и няма да се бори за прием в тази градина</li>
                     </ul>
                  </li>
               </ul>
               <h3>Безплатно ползване</h3>
               <p>Ползването на пълната функционалност на калкулатора винаги е било и ще остане безплатно, както до сега, но за следващия прием през 2023 година ще бъде въведен лимит на броя безплатни изчисления. Лимитът ще може да бъде премахнат срещу скромна еднократна такса. Целта е да се намали натоварването върху калкулатора и системата на детските градини в София, както и да се подпомогне периодичното обновяване, поддъжката и скалирането на услугата в пиковите периоди преди класиранията.</p>
            </article>
            <aside>
               <h3>Пример</h3>
               <p><b>Анализ на шанс за ДГ 151</b><i>1</i></p>
               <ul>
                  <li>11 точки (трето желание с 1 бонус точка)</li>
                  <li>17 места общо (обявени свободни места)</li>
                  <li>4 места за деца с 11 точки (0 места без дълбок анализ)</li>
                  <li>92% шанс за прием (0% шанс без дълбок анализ) </li>
               </ul>
               <img alt="Примерен дълбок анализ на шансове за прием" src="../example.png" />
               <p><b>Анализ на конкурент АИО</b><i>2</i></p>
               <p>64% шанс да отпадне като конкурент:</p>
               <ul>
                  <li>11 точки (трето желание с 1 бонус точка)</li>
                  <li>36% тежест (общ шанс за прием по предходните желания е 64%)</li>
                  <li>няма сигурен прием (няма 100% шанс за прием на нито едно предходно желание)</li>
               </ul>
               <p><b>Анализ на конкурент ДНБ</b><i>3</i></p>
               <p>100% шанс да отпадне като конкурент:</p>
               <ul>
                  <li>11 точки (желание без бонус точки)</li>
                  <li>0% тежест (общ шанс за прием по предходните желания е 100%)</li>
                  <li>сигурен прием в ДГ 195 (има 100% шанс за прием на предходно желание в ДГ 195)</li>
               </ul>
            </aside>
         </section>
      );
   }
}