import React, { Component } from 'react';

import Select from './Select.js';

export default class Years extends Component {
   constructor(props) {
      super();

      const year = new Date().getFullYear();
      let years = [ year - 1, year - 2,  year - 3, year - 4, year - 5, year - 6 ];

      years = years.map(year => ({ id: year, name: year }));

      this.state = { years, selected: years.find(year => year.id === props.year) };
      this.onSelect = this.onSelect.bind(this);
   }

   onSelect(selected) {
      this.setState({ selected }, () => this.props.onSelect(selected.id));
   }

   render() {
      let { years, selected } = this.state;
      return (
         <Select selected={selected} options={years} placeholder="Набор на дете" onSelect={this.onSelect} />
      );
   }
}