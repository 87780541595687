import React from 'react';

import './Message.css';

export const MessageTypes = {
   info: 'info',
   error: 'error'
};

export default function Message(props) {
   const { type, text, button, url } = props;
   return (
      <section className="Message">
         <p className={MessageTypes[type] || MessageTypes.info}>
            <strong>{text}</strong>
            {button && url
               ? <a href={url}>{button}</a>
               : null
            }
         </p>
      </section>
   );
}