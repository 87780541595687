import React, { Component } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import config from '../config.js';
import Payment from './Payment.js';

import './Subscription.css';

const stripePromise = loadStripe(config.stripe.publicKey);

export default class Subscription extends Component {
   render() {
      const { id, paymentSecret } = this.props;
      const options = {
         clientSecret: paymentSecret,
         appearance: {}
       };

      return (
         <section className="Subscription">
            <article>
               <h3>Предимства на абонамента</h3>
               <p>Калкулаторът изчислява шансовете за класиране към текущия момент. Това означава, че записването на нови деца в системата на детските градини на София (<b>kg.sofia.bg</b>), както и разместването на желаният на останалите кандидатстващи деца се отразява пряко върху шансовете и те се променят с времето.</p>
               <p>За да има калкулаторът максимален ефект, шансовете е добре да се проверяват поне веднъж седмично в началото и ежедневно през последните седмици преди класирането. Така, ако се налага, могат да се взимат своеверменни мерки:</p>
               <ul>
                  <li>Разместване подредбата на текущите желания</li>
                  <li>Премахване на детски градини, където конкуренцията е твърде голяма и шансовете са много ниски</li>
                  <li>Добавяне на детски градини с новооткрити места или отлив на желаещи</li>
               </ul>
               <h3>Какво представлява абонаментът</h3>
               <p>Абонаментът е персонален за всяко едно дете и е свързан с неговото ID:</p>
               <ul>
                  <li>Предоставя възможност за неограничен брой изчисления на шансовете за класиране</li>
                  <li>Важи за всички класирания през текущата календарна година</li>
                  <li>Валиден е до 31 декември на текущата година независимо от датата на плащане</li>
                  <li>Не може да се прехвърля за класирания през следващата календарна година</li>
                  <li>Стойността му е само <strike>25.00 лв</strike> <b>20.00 лв</b> на година (или <strike>2.00 лв</strike> <b>1.60 лв</b> на месец)</li>
               </ul>
            </article>
            <aside>
               <h3>Плати абонамент за ID {id}</h3>
               <dl>
                  <dt>Годишен абонамент<p>ID {id}</p></dt>
                  <dd><strike>25 лв</strike> 20 лв</dd>
               </dl>               
               <Elements stripe={stripePromise} options={options}>
                  <Payment />
               </Elements>
            </aside>
         </section>
      );
   }
}