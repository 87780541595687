import React, { useState, useEffect, useCallback } from 'react';

import api from './api';
import Years from './components/Years.js';
import Chances from './components/Chances.js';
import Description from './components/Description.js';
import Subscription from './components/Subscription.js';
import Loading from './components/Loading.js';
import Message, { MessageTypes } from './components/Message.js';
import './App.css';

const pages = {
   home: '',
   payment: 'payment',
   calculation: 'calculation',
   subscription: 'subscription'
};

export default function App() {
   const page = getPage();
   
   const [ state, setState ] = useState({
      page: page || '',
      id: '', // 19000818,
      year: '', // 2019,
      chances: [],
      loading: false,
      timestamp: Date.now(),
      paymentId: '',
      paymentSecret: '',
      paymentStatus: '',
      message: {}
   });

   const onHashChange = useCallback(() => {
      const page = getPage();

      setState(state => {
         if (page !== state.page) {
            if (Object.values(pages).includes(page)) {
                  if (page === pages.subscription && !state.paymentSecret) {
                     return state;
                  }
                  return { ...state, page };
            }
            window.location.hash = pages.home;
         }
         return state;
      });
   }, []);

   useEffect(() => {
      const params = new URLSearchParams(window.location.search);
      const paymentId = params.get('payment_intent');
      const paymentSecret = params.get('payment_intent_client_secret');

      window.addEventListener('hashchange', onHashChange);

      if (paymentId && paymentSecret) {
         window.location = `/#${pages.payment}`;
      }
      return () => {
         window.removeEventListener('hashchange', onHashChange);
      }
   }, [ onHashChange ]);

   useEffect(() => {
      if (state.page === pages.home) {
         setState(state => ({ ...state, chances: [] }));
      }
      else if (state.page === pages.payment) {
         const message = { type: MessageTypes.info, text: 'Плащането е извършено успешно.' };
         setState(state => ({ ...state, message }));
      }
   }, [ state.page ]);

   function getPage() {
      const [ page ] = window.location.hash.replace('#', '').split('/');
      return page;
   }

   function onYear(year) {
      setState(state => ({ ...state, year }));
   };

   function onId(event) {
      const { value } = event.target;

      if (/^(|\d+)$/.test(value)) {
         setState(state => ({ ...state, id: Number(value) || '' }));
      }
   }

   function onCalculate(event) {
      const currentYear = new Date().getFullYear();

      event.preventDefault();
      window.location.hash = pages.calculation;

      if (!(/^\d{8}$/.test(state.id)) || state.year > currentYear - 1 || state.year < currentYear - 6) {
         const message = { type: MessageTypes.error, text: 'Невалиден набор или ID на дете.' };
         setState(state => ({ ...state, message }));
         return;
      }
      setState(state => ({ ...state, loading: true }));
      calculate(state.id, state.year);
   }

   function calculate(id, year) {
      api.calculate(id, year)
         .then(data => {
            let chances = [];
            let paymentId = '';
            let paymentSecret = '';
            let paymentStatus = '';
            let message = { type: MessageTypes.error, text: 'Проблем с връзката към системата на детските градини.' };
   
            if (data) {
               paymentId = data.paymentId;
               paymentSecret = data.paymentSecret;
               paymentStatus = data.paymentStatus;

               if (data.chances) {
                  chances = data.chances;

                  if (chances.length) {
                     if (paymentStatus === 'succeeded') {
                        const year = new Date(data.paymentCreated * 1000).getFullYear();
                        message = { type: MessageTypes.info, text: `Имате неограничен брой калкулации до края на ${year} година. Благодаря за подкрепата.` };
                     }
                     else {
                        message = { type: MessageTypes.info, text: `Остават ви ${data.calculations} безплатни калкулации. Aбонирайте се, за да премахнете лимита.`, button: 'Абонамент', url: `#${pages.subscription}` };
                     }
                  }
                  else {
                     message = { type: MessageTypes.error, text: 'Няма намерени данни.' };
                  }
               }
               else {
                  message = { type: MessageTypes.error, text: 'Вашите безплатните калкулации са изчерпани. Aбонирайте се, за да премахнете лимита.', button: 'Абонамент', url: `#${pages.subscription}` };
               }
            }
            setState(state => ({ 
               ...state,
               message,
               chances,
               paymentId,
               paymentSecret,
               paymentStatus,
               loading: false,
               timestamp: Date.now()
            }));
         })
         .catch(error => console.log(error));
   }

   return (
      <div className="App">
         <header>
            <a href="/#"><img alt="Малко дете" src="logo.svg" /></a>
            <h1>Калкулатор на шансове</h1>
            <h2>за прием в детски градини в София</h2>
            <form>
               <Years year={state.year} onSelect={onYear} />
               <input type="text" maxLength="8" placeholder="ID на дете" value={state.id} onChange={onId} />
               <button onClick={onCalculate}>Изчисли</button>
            </form>
         </header>
         <main>
            {state.message.type
               ? <Message type={state.message.type} text={state.message.text} button={state.message.button} url={state.message.url} />
               : null
            }
            {state.page === pages.subscription && state.paymentSecret
               ? <Subscription id={state.id} paymentSecret={state.paymentSecret}/>
               : state.page === pages.calculation && state.chances.length
                  ? <Chances timestamp={state.timestamp} chances={state.chances} />
                  : <Description />
            }
            {state.loading
               ? <Loading />
               : null
            }
         </main>
      </div>
   );
}