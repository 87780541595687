export default async function calculate(id, year) {
   const delay = 5 * 1000;
   const delayLimit = 3 * 60 * 1000;

   const parameters = {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id, year })
   };
   let timeout;

   return new Promise(resolve => {
      (function check(retries = 0) {
         fetch('/api/calculate', parameters)
            .then(response => {
               switch(response.status) {
                  case 200:
                     resolve(response.json());
                     break;
                  case 204:
                     if (retries * delay < delayLimit) {
                        clearTimeout(timeout);
                        timeout = setTimeout(() => check(++retries), delay);
                        break;
                     } // falls through
                  default:
                     resolve();
               }
            });
      })();
   });
}